import "@/scss/styles.scss";
import { getGlobal } from "@/functions/getGlobal";
import { Suspense } from "react";
import { SiteFooter, SiteHeader } from "@/ui";
import CookieConsent from '@/components/Shared/CookieConsent';
import SourceFlowTracker from "@sourceflow-uk/sourceflow-tracker";
import Script from "next/script";

export default function App({ Component, pageProps }) {
  const global = getGlobal();
  const gtag = global["_theme.google.gtag"];

  return (
    <Suspense>
      <SiteHeader className={global["_theme.header.classes"]} />
      <main className="flex-grow-1">
        <Component {...pageProps} />
      </main>
      <SiteFooter />
      {/* <ReciteMeWidget /> */}
      <CookieConsent>
        <SourceFlowTracker cookieExpiryTime={3000} />
      </CookieConsent>
       <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag}`}
      />
      <Script
        id="gtm_script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag}');
              `,
        }}
      />
    </Suspense>
  );
}
